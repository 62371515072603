<template>
	<main id="home" class="home">
		<article
			class="home__opening"
			:style="{
				'background-image': `url(${$imgUrl(background)})`
			}"
		>
			<div class="home__opening-copy fadeIn">
				<h1 class="heading__h1" v-html="$t('home.title')" />
				<p class="home__opening-paragraph">
					{{ $t('home.p1') }}
				</p>
				<a class="button__primary" href="#contact" v-smooth-scroll>
					{{ $t('buttons.contact_us') }}
				</a>
			</div>
			<div class="home__opening-arrow">
				<img :src="$imgUrl('arrow_down.svg')" alt="arrow" />
			</div>
			<socials />
		</article>
		<article class="home__content">
			<section id="about" class="about">
				<div class="about__wrap">
					<div class="about__image">
						<img :src="$imgUrl('boy.png')" alt="boy" />
					</div>
					<div class="about__text">
						<h2 class="heading__h2 fadeIn">
							{{ $t('about.title') }}
						</h2>
						<p class="paragraph fadeIn">
							{{ $t('about.p1') }}
						</p>
						<p class="paragraph fadeIn">
							{{ $t('about.p2') }}
						</p>
						<p class="paragraph fadeIn">
							{{ $t('about.p3') }}
						</p>
						<p class="paragraph fadeIn">
							{{ $t('about.p4') }}
						</p>
					</div>
				</div>
			</section>
			<section id="mission" class="mission">
				<div class="mission__text">
					<h2 class="heading__h2 fadeIn">
						{{ $t('mission.title') }}
					</h2>
					<p class="paragraph fadeIn">
						{{ $t('mission.p1') }}
					</p>
					<p class="paragraph fadeIn">
						{{ $t('mission.p2') }}
					</p>
				</div>
				<div class="mission__background" />
				<div class="mission__scribble" />
			</section>
			<section id="why_us" class="why-us">
				<div class="why-us__image">
					<img :src="$imgUrl('floating_letters.svg')" alt="letters" />
				</div>
				<div class="why-us__text">
					<h2 class="heading__h2 fadeIn">
						{{ $t('why-us.title') }}
					</h2>
					<p class="paragraph fadeIn">
						{{ $t('why-us.p1') }}
					</p>
				</div>
			</section>
			<section id="offer" class="offer">
				<div class="offer__wrap">
					<div class="offer__text">
						<h2 class="heading__h2 fadeIn">
							{{ $t('offer.title') }}
						</h2>
						<p class="paragraph fadeIn">
							{{ $t('offer.p1') }}
						</p>
					</div>
					<div class="offer__image">
						<img :src="$imgUrl('wordle.png')" alt="scribble" />
					</div>
				</div>
			</section>
			<section id="contact" class="contact">
				<div class="contact__image">
					<img :src="$imgUrl('paper_plane.svg')" alt="plane" />
				</div>
				<div class="contact__text">
					<h2 class="heading__h2 fadeIn">
						{{ $t('contact.title') }}
					</h2>
					<p class="paragraph fadeIn">
						{{ $t('contact.p1') }}
					</p>
					<p class="paragraph fadeIn">
						{{ $t('contact.p2') }}
					</p>
					<a :href="`mailto:${$t('external.email')}`" class="contact__external fadeIn">
						{{ $t('external.email') }}
					</a>
					<p class="paragraph fadeIn">
						{{ $t('contact.p3') }}
					</p>
					<div class="contact__external-wrap fadeIn">
						<a :href="`tel:+48${$t('external.phone1')}`" class="contact__external">
							{{ $t('external.phone1') }}
						</a>
					</div>
				</div>
				<div class="contact__scribble" />
			</section>
		</article>
		<div id="plane1" />
		<div id="plane2" />
	</main>
</template>

<script>
import Socials from '@/components/socials'
import plane from '@/plugins/plane'

export default {
	components: { Socials },
	computed: {
		background: ({ width }) => width >= 900 ? 'main_image.jpg' : 'main_image_mobile.jpg'
	},
	data: () => ({
		fadeIn: [],
		width: 1000
	}),
	mounted() {
		this.fadeIn = document.querySelectorAll('.fadeIn')

		for (let i = 0; i < this.fadeIn.length; i++) {
			this.fadeIn[i].style.opacity = '0'
		}

		this.checkViewport()

		window.addEventListener('scroll', this.checkViewport)
		window.addEventListener('resize', this.checkViewport)

		plane()
	},
	methods: {
		checkViewport() {
			this.width = window.innerWidth

			for (let i = 0; i < this.fadeIn.length; i++) {
				if (
					this.fadeIn[i].getBoundingClientRect().top -
						window.outerHeight * 0.8 <=
					0
				) {
					this.fadeIn[i].style.opacity = '1'
				}
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.checkViewport)
		window.removeEventListener('resize', this.checkViewport)
	}
}
</script>
