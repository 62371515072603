import Home from '../pages/home'
import PageNotFound from '../pages/404'

export const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '*',
		name: 'PageNotFound',
		component: PageNotFound
	}
]
