<template>
	<div :style="{ opacity }">
		<!-- Nav Links -->
		<div
			:class="['navMenu', { 'nav-label': menuActive }]"
			:style="[menuDirection, menuWidth]"
		>
			<div>
				<a
					@click="closeMenu()"
					v-for="link in $store.getters['settings/menu']"
					:key="link.id"
					:href="`#${link.url}`"
					class="menu_link"
					v-smooth-scroll
				>
					{{ $t(`menu.${link.text}`) }}
				</a>
			</div>
			<div>
				<lang-switch />
				<socials />
			</div>
		</div>
		<!-- Hamburger Menu -->
		<button
			:class="[
				'navIcon',
				'hamburger',
				'hamburger--spring',
				{ 'is-active': menuActive },
				{ open: menuActive }
			]"
			@click="toggleMenu"
		>
			<span class="hamburger-box">
				<span class="hamburger-inner" />
			</span>
		</button>
	</div>
</template>

<script>
import LangSwitch from './lang-switch'
import Socials from '@/components/socials'

export default {
	name: 'slider',
	props: {
		direction: {
			type: String,
			required: false,
			default: 'left'
		},
		opacity: {
			type: Number,
			required: false,
			default: 0
		}
	},
	data: () => ({
		menuWidth: {
			width: 0
		},
		menuActive: false
	}),
	components: {
		LangSwitch,
		Socials
	},
	mounted() {
		window.addEventListener('resize', this.closeMenu)
	},
	computed: {
		menuDirection() {
			return this.direction === 'right' ? { right: 0 } : { left: 0 }
		},
		iconDirection() {
			return this.direction === 'right'
				? {
						float: 'right'
				  }
				: {
						float: 'left'
				  }
		},
		app() {
			return document.getElementById('app')
		}
	},
	methods: {
		openMenu() {
			this.menuWidth = { width: '100%' }
			this.menuActive = true
			this.$emit('menu-active', true)
			document.documentElement.style.overflow = 'hidden'
		},
		closeMenu() {
			this.menuWidth = { width: 0 }
			if (this.app) {
				this.menuActive = false
				this.$emit('menu-active', false)
				document.documentElement.style.overflow = 'auto'
				this.app.style.marginLeft = '0'
				this.app.style.marginRight = '0'
			}
		},
		toggleMenu() {
			if (this.menuWidth.width === 0) {
				this.openMenu()
			} else {
				this.closeMenu()
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeMenu)
	}
}
</script>
