<template>
	<div id="app">
		<app-header @change-opacity="changeOpacity" :menu-active="menuActive" />
		<transition name="fade" mode="out-in">
			<router-view />
		</transition>
		<menu-slider direction="right" :opacity="opacity" @menu-active="menuToggle" />
		<app-footer />
	</div>
</template>

<script>
import MenuSlider from '@/components/menu-slider'
import AppHeader from '@/components/app-header'
import AppFooter from '@/components/app-footer'

export default {
	name: 'App',
	components: {
		MenuSlider,
		AppHeader,
		AppFooter
	},
	computed: {
		logo: ({ $i18n }) =>
			$i18n.locale === 'pl' ? 'ZZ_logo_white.png' : 'kuestion_mark_logo_white.png'
	},
	data: () => ({
		opacity: 1,
		menuActive: false
	}),
	methods: {
		changeOpacity(val) {
			this.opacity = val
		},
		menuToggle(bool) {
			this.menuActive = bool
		}
	}
}
</script>
