const addClass = (el, id) => {
	if (window.innerWidth <= 600) {
		el.classList.add(`plane${id}mobile`)
	} else {
		el.classList.add(`plane${id}`)
	}
}

const removeClass = (el, id) => {
	if (el.classList.contains(`plane${id}mobile`)) {
		el.classList.remove(`plane${id}mobile`)	
	} else {
		el.classList.remove(`plane${id}`)
	}
}

export default () => {
	const plane1 = document.querySelector('#plane1')
	const plane2 = document.querySelector('#plane2')

	setTimeout(() => {
		addClass(plane1, 1)
		setTimeout(() => {
			removeClass(plane1, 1)
		}, 5000);

		setInterval(() => {
			addClass(plane1, 1)
			setTimeout(() => {
				removeClass(plane1, 1)
			}, 5000);
		}, 20000);
	}, 5000);

	setInterval(() => {
		addClass(plane2, 2)
		setTimeout(() => {
			removeClass(plane2, 2)
		}, 5000);
	}, 20000);
}