import Vue from 'vue'
import VueRouter from 'vue-router'
import VueSmoothScroll from 'vue2-smooth-scroll'

import { routes } from './routes'

Vue.use(VueRouter)
Vue.use(VueSmoothScroll, { duration: 400, updateHistory: false })

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
})

export default router
