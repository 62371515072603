<template>
	<header
		:class="['header__wrap', { 'box-shadow': shadowActive }]"
		:style="style"
	>
		<a href="#home" v-smooth-scroll>
			<img
				class="logo"
				:src="$imgUrl('LAB_favicon.png')"
				:style="logoStyle"
			/>
			<transition name="fade" mode="in-out">
				<img
					v-if="style.background === 'transparent'"
					class="logo_text"
					:src="$imgUrl('logo_text.svg')"
					:key="style.background"
				/>
			</transition>
		</a>
		<div class="header__menu-wrap" :style="menuStyle">
			<transition name="fade" mode="in-out">
				<lang-switch
					v-if="style.background === 'transparent'"
					class="header__flags"
					:key="style.background"
				/>
			</transition>
			<nav
				class="header__menu"
			>
				<a
					v-for="link in menu"
					:key="link.id"
					:href="`#${link.url}`"
					v-smooth-scroll
				>
					{{ $t(`menu.${link.text}`) }}
				</a>
			</nav>
		</div>
	</header>
</template>
<script>
import LangSwitch from './lang-switch'
export default {
	props: {
		menuActive: {
			type: Boolean,
			default: false
		}
	},
	components: {
		LangSwitch
	},
	data: () => ({
		lastScrollTop: 0,
		scrolled: 0,
		shadowActive: false,
		style: {
			opacity: 1,
			background: 'transparent',
			height: '8rem'
		},
		logoStyle: {
			height: '8rem'
		},
		menuStyle: {
			'margin-top': '2rem'
		},
		width: 1000
	}),
	computed: {
		menu: ({ $store }) => $store.getters['settings/menu'],
		url: ({ $route }) => $route.path
	},
	mounted() {
		window.addEventListener('scroll', this.scrollFunc)
		this.scrollFunc()
	},
	watch: {
		$route() {
			this.scrollFunc()
		},
		menuActive() {
			this.scrollFunc()
		}
	},
	methods: {
		goHome() {
			this.$router.history.push({ path: '/' })
		},
		scrollFunc() {
			this.width = window.innerWidth

			this.scrolled = Math.max(
				window.pageYOffset,
				document.documentElement.scrollTop,
				document.body.scrollTop
			)

			if (this.scrolled >= 100) {
				if (this.scrolled > this.lastScrollTop) {
					this.style = {
						opacity: 0,
						background: '#65dfa4',
						height: this.width >= 900 ? '6rem' : '7rem'
					}
					this.logoStyle = {
						height: this.width >= 900 ? '8rem' : '9rem'
					}
					this.menuStyle = {
						'margin-top': 0
					}
					this.$emit('change-opacity', 0)

					this.shadowActive = this.menuActive
				} else {
					this.style = {
						opacity: 1,
						background: '#65dfa4',
						height: this.width >= 900 ? '6rem' : '7rem'
					}
					this.logoStyle = {
						height: this.width >= 900 ? '9rem' : '9rem'
					}
					this.menuStyle = {
						'margin-top': 0
					}
					this.$emit('change-opacity', 1)

					this.shadowActive = !this.menuActive
				}
			} else {
				this.style = {
					opacity: 1,
					background: 'transparent',
					height: this.width >= 900 ? '10rem' : '7rem'
				}
				this.logoStyle = {
					height: this.width >= 900 ? '12rem' : '9rem'
				}
				this.menuStyle = {
					'margin-top': this.width >= 900 ? '2rem' : 0
				}
				this.$emit('change-opacity', 1)
				this.shadowActive = this.menuActive ? false : this.url !== '/'
			}
			this.lastScrollTop = this.scrolled <= 0 ? 0 : this.scrolled
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollFunc)
	}
}
</script>
