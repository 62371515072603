import Vue from 'vue'
import VueI18n from 'vue-i18n'
import eng from './languages/eng.json'
import pl from './languages/pl.json'
import Functions from './functions'

Vue.use(VueI18n)

export const i18n = new VueI18n({
	locale: 'pl',
	fallbackLocale: 'en',
	messages: {
		eng,
		pl
	},
	postTranslation: (str) => Functions.removeOrphans(str)
})
