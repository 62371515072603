export default {
	namespaced: true,
	state: {
		menu: [
			{
				id: 0,
				text: 'about',
				url: 'about'
			},
			{
				id: 1,
				text: 'our_mission',
				url: 'mission'
			},
			{
				id: 2,
				text: 'why_us',
				url: 'why_us'
			},
			{
				id: 3,
				text: 'offer',
				url: 'offer'
			},
			{
				id: 4,
				text: 'contact',
				url: 'contact'
			}
		]
	},
	getters: {
		menu: (state) => state.menu
	},
	mutations: {},
	actions: {}
}
