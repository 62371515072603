<template>
	<footer class="footer__wrap">
		<div class="footer__icons">
			<router-link :to="{ path: '/' }">
				<img :src="$imgUrl('logo_white.svg')" />
			</router-link>
			<socials />
		</div>
		<div class="footer__copy-wrap">
			<p class="footer__copy">{{ $t('footer.copy') }}</p>
			<div class="footer__credential">
				<span>{{ $t('external.credential.p1') }}</span>
				<a :href="$t('external.credential.author1.link')" target="_blank">
					{{ $t('external.credential.author1.name') }}
				</a>
				<a :href="$t('external.credential.author2.link')" target="_blank">
					{{ $t('external.credential.author2.name') }}
				</a>
				<a :href="$t('external.credential.author3.link')" target="_blank">
					{{ $t('external.credential.author3.name') }}
				</a>
				<span>{{ $t('external.credential.p2') }}</span>
				<a :href="$t('external.credential.website.link')" target="_blank">
					{{ $t('external.credential.website.name') }}
				</a>
			</div>
		</div>
	</footer>
</template>
<script>
import Socials from '@/components/socials'

export default {
	components: { Socials }
}
</script>
